<template>
  <section class="base-page">
    <ProductPageHeader />
    <keep-alive><FilterSection @change="onFilterChange"/></keep-alive>
    <BasicTable
      showSelection
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <template #tableControl>
        <el-button
          size="small"
          class="mt-r"
          type="primary"
          icon="el-icon-document-add"
          @click="addRow"
          v-if="checkPermission(['PRODUCT_ADMIN'])"
          >新增</el-button
        >
        <el-checkbox class="mt-l mt-r" v-model="showDisable" @change="onSearch"
          >显示禁用</el-checkbox
        >
        &nbsp; &nbsp;
      </template>
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'disabled'">
              <el-tag
                :type="scope.row.disabled ? 'danger' : 'success'"
                @click="toggleDisableRow(scope.row, scope.$index)"
                >{{ scope.row.disabled ? "已禁用" : "已启用" }}</el-tag
              >
            </span>
            <span v-else-if="column.prop === 'createdAt'">
              {{ scope.row.createdAt | date }}
            </span>
            <span v-else-if="column.prop === 'lastModifiedAt'">
              {{ scope.row.lastModifiedAt | date }}
            </span>
            <span v-else-if="column.prop === 'posterImageUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.posterImageUrl"
                :preview-src-list="[scope.row.posterImageUrl]"
              >
              </el-image>
            </span>
            <span v-else-if="column.prop === 'coverImageUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.coverImageUrl"
                :preview-src-list="[scope.row.coverImageUrl]"
              >
              </el-image>
            </span>
            <span v-else-if="column.prop === 'renderingImageUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.renderingImageUrl"
                :preview-src-list="[scope.row.renderingImageUrl]"
              >
              </el-image>
            </span>
            <span v-else-if="column.prop === 'thumbnailUrl'">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.thumbnailUrl"
                :preview-src-list="[scope.row.thumbnailUrl]"
              >
              </el-image>
            </span>

            <span v-else-if="column.prop === 'name'">
              <span>{{ scope.row.name ? scope.row.name.cn : "" }}</span>
            </span>
            <span v-else-if="column.prop === 'star'">
              <span>{{ scope.row.star ? "星标" : "非星标" }}</span>
            </span>

            <span v-else-if="column.prop === 'colorName'">
              <span>{{
                scope.row.colorName ? scope.row.colorName.cn : ""
              }}</span>
            </span>
            <span v-else-if="column.prop === 'introduction'">
              <span>{{
                scope.row.introduction ? scope.row.introduction.cn : ""
              }}</span>
            </span>
            <span v-else-if="column.prop === 'description'">
              <span>{{
                scope.row.description ? scope.row.description.cn : ""
              }}</span>
            </span>
            <span v-else-if="column.prop === 'onelineDescription'">
              <span>{{
                scope.row.onelineDescription
                  ? scope.row.onelineDescription.cn
                  : ""
              }}</span>
            </span>
            <span v-else-if="column.prop === 'series'">
              <span>{{
                scope.row.series.map((item) => item.name).join(",")
              }}</span>
            </span>
            <span v-else-if="column.prop === 'albums'">
              <el-popover
                placement="right"
                width="450"
                trigger="click"
                v-if="scope.row.albums.length > 0"
              >
                <el-carousel height="300px">
                  <el-carousel-item
                    v-for="item in scope.row.albums"
                    :key="item.url"
                    :title="`${item.name.cn}`"
                  >
                    <img
                      :src="item.url"
                      alt="图册"
                      class="preview-img contain"
                    />
                  </el-carousel-item>
                </el-carousel>
                <span class="text-nav" slot="reference"
                  >{{ scope.row.albums.length }}张</span
                >
              </el-popover>
              <span v-else>暂无图片</span>
            </span>
            <span v-else-if="column.prop === 'photos'">
              <el-popover
                placement="right"
                width="450"
                trigger="click"
                v-if="scope.row.photos.length > 0"
              >
                <el-carousel height="300px">
                  <el-carousel-item
                    v-for="item in scope.row.photos"
                    :key="item.url"
                    :title="`${item.typeName}`"
                  >
                    <img
                      :src="item.url"
                      alt="实拍图"
                      class="preview-img contain"
                    />
                  </el-carousel-item>
                </el-carousel>
                <span class="text-nav" slot="reference"
                  >{{ scope.row.photos.length }}张</span
                >
              </el-popover>
              <span v-else>暂无图片</span>
            </span>
            <span v-else-if="column.prop === 'elementMedias'">
              <el-popover
                placement="right"
                width="450"
                trigger="click"
                v-if="scope.row.elementMedias.length > 0"
              >
                <el-carousel height="300px">
                  <el-carousel-item
                    v-for="item in scope.row.elementMedias"
                    :key="item.url"
                    :title="`${item.typeName}`"
                  >
                    <img
                      :src="item.url"
                      alt="元素图"
                      class="preview-img contain"
                    />
                  </el-carousel-item>
                </el-carousel>
                <span class="text-nav" slot="reference"
                  >{{ scope.row.elementMedias.length }}张</span
                >
              </el-popover>
              <span v-else>暂无图片</span>
            </span>
            <span v-else-if="column.prop === 'files'">
              <el-popover
                placement="right"
                width="300"
                trigger="click"
                v-if="scope.row.files.length > 0"
              >
                <ul class="series-file-list">
                  <li
                    class="list-item"
                    v-for="item in scope.row.files"
                    :key="item.url"
                    :title="`${item.nameCn} | ${item.nameEn}`"
                  >
                    <a
                      :href="item.url"
                      target="_blank"
                      rel="noopener noreferrer"
                      :download="item.nameEn"
                    >
                      <p>中文: {{ item.name.cn }}</p>
                      <p>英文: {{ item.name.en }}</p>
                    </a>
                  </li>
                </ul>
                <span class="text-nav" slot="reference"
                  >{{ scope.row.files.length }}个文件</span
                >
              </el-popover>
              <span v-else>暂无文件</span>
            </span>

            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.canUpdate"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              修改
            </el-button>
            <el-button
              v-if="scope.row.canUpdate"
              @click.native.prevent="handleCopy(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              复 制
            </el-button>

            <el-button
              class="text-danger"
              v-if="scope.row.canDisable"
              @click.native.prevent="toggleDisableRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              {{ scope.row.disabled ? "启用" : "禁用" }}
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template #multipleControl>
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(false)"
          :disabled="multipleSelected.length <= 0"
          >启用 {{ multipleSelected.length }}</el-button
        >
        <el-button
          class="mt-r"
          size="small"
          @click="onMultipleToggleDisable(true)"
          :disabled="multipleSelected.length <= 0"
          >禁用 {{ multipleSelected.length }}</el-button
        >
        <el-button
          class="mt-r"
          size="small"
          @click="onUpdateSerires"
          :disabled="multipleSelected.length <= 0"
          >更新系列 {{ multipleSelected.length }}</el-button
        >
      </template>
    </BasicTable>
    <UpdateSeriesOfProducts
      :show="showUpdateSeriesDialog"
      :pids="multipleSelectedIds"
      @success="onUpdateSeriesSuccess"
      @close="onUpdateSeriesClose"
    />
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetProductsList, ToogleDisableByIds } from "./api";
import {
  GetUpdateSeriesCheck,
  GetDisableCheck,
  GetUpdateCheck,
} from "./api/check";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import {
  GetTableOrderParams,
  onClearFilter,
  FormatMultipleItemIds,
  GetTableDisableMessage,
} from "@/utils/table";
import ProductPageHeader from "@/components/ProductPageHeader";
import UpdateSeriesOfProducts from "@/views/products/components/UpdateSeriesOfProducts";
import FilterSection from "./components/FilterSection";
import config from "@/config";
export default {
  components: {
    BasicTable,
    FilterSection,
    ProductPageHeader,
    UpdateSeriesOfProducts,
  },
  data() {
    return {
      formDialog: false,
      tableLoading: false,
      multipleFormDialog: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "seq",
      orderDirection: "descending",
      currentId: null,
      currentCopyId: null,
      multipleSelected: [],
      showDisable: false,
      orderPropObj: {},
      showUpdateSeriesDialog: false,
    };
  },
  computed: {
    images() {
      return this.tableData.map((item) => item.menuImageUrl);
    },
    localeColumns() {
      return this.$store.getters.localeColumns;
    },
    multipleSelectedIds() {
      return this.multipleSelected.map((item) => item.id);
    },
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        this.tableColumns = [
          {
            prop: "disabled",
            label: "状态",
            showColumn: true,
            width: 100,
            align: "center",
            fixed: "left",
            sortable: "custom",
          },
          {
            prop: "thumbnailUrl",
            label: "元素图",
            showColumn: true,
            width: 100,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "coverImageUrl",
            label: "效果图",
            showColumn: true,
            width: 100,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "renderingImageUrl",
            label: "渲染图",
            showColumn: true,
            width: 100,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "name",
            label: "名称",
            minWidth: 130,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          // {
          //   prop: "introduction",
          //   label: "简介",
          //   minWidth: 150,
          //   showColumn: true,
          //   align: "left",
          //   sortable: "custom",
          // },
          // {
          //   prop: "description",
          //   label: "描述",
          //   showColumn: true,
          //   align: "left",
          //   sortable: "custom",
          // },

          {
            prop: "elementMedias",
            label: "元素图",
            width: 150,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "series",
            label: "系列",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "colorName",
            label: "色系",
            minWidth: 150,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },

          {
            prop: "code",
            label: "编号",
            minWidth: 80,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "onelineDescription",
            label: "一句话描述",
            minWidth: 160,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "albums",
            label: "图册",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "photos",
            label: "实拍图",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "files",
            label: "附件",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "star",
            label: "星标",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: false,
          },
          {
            prop: "seq",
            label: "次序",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "id",
            label: "ID",
            width: 80,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },

          {
            prop: "createdByName",
            label: "创建人",
            width: 100,
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          {
            prop: "createdAt",
            label: "创建时间",
            width: 120,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedByName",
            label: "更新人",
            width: 100,
            showColumn: true,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "lastModifiedAt",
            label: "更新时间",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            align: "center",
            showColumn: true,
            label: "操作",
            width: 130,
          },
        ];
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },

    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetProductsList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        disabled: this.showDisable,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.tableData = this.tableData.map((item) => {
            return {
              ...item,
              canUpdate: true,
              canDisable: true,
              canUpdateSeries: true,
            };
          });
          this.onUpdateCheck();
          this.onDisableCheck();
          this.onUpdateSeriesCheckCheck();
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.getTableData();
    },
    onFilterChange(data) {
      this.tableFilters = data;
      this.page = 0;
      this.onSearch();
    },
    onClearAndRefresh() {
      this.orderProp = "seq";
      this.orderDirection = "descending";
      this.onResetFilter();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.page = 0;
      this.onRefresh();
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "seq";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    addRow() {
      this.$router.push({
        name: "ProductFormPage",
        params: {
          id: null,
        },
      });
    },
    editRow(row) {
      if (row.canUpdate) {
        this.$router.push({
          name: "ProductFormPage",
          params: {
            id: row.id,
          },
          query: {
            name: row.name.cn,
          },
        });
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },
    handleCopy(row) {
      this.$router.push({
        name: "ProductFormPage",
        params: {
          copyId: row.id,
        },
        query: {
          name: row.name.cn,
        },
      });
    },

    async onUpdateCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetUpdateCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canUpdate = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canUpdate = true;
          return item;
        });
      }
    },
    async onDisableCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetDisableCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canDisable = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canDisable = true;
          return item;
        });
      }
    },

    async onUpdateSeriesCheckCheck() {
      try {
        let ids = this.tableData.map((item) => item.id);
        if (ids.length > 0) {
          let res = await GetUpdateSeriesCheck(ids);
          this.tableData = this.tableData.map((item) => {
            item.canUpdateSeries = res.data;
            return item;
          });
        }
      } catch (error) {
        this.tableData = this.tableData.map((item) => {
          item.canUpdateSeries = true;
          return item;
        });
      }
    },

    onFormCancel() {
      this.currentId = null;
      this.multipleFormDialog = false;
      this.formDialog = false;
    },

    onUpdateSerires() {
      let canUpdate = this.multipleSelected.every(
        (item) => item.canUpdateSeries == true
      );
      if (canUpdate) {
        this.showUpdateSeriesDialog = true;
      } else {
        this.$message.error("无权限操作");
      }
    },
    // 批量：启用/禁用
    onMultipleToggleDisable(flag = false) {
      if (this.multipleSelected.length <= 0) {
        return false;
      }
      let { ids, nameString, unableControl } = FormatMultipleItemIds({
        multipleSelectes: this.multipleSelected,
        nameKey: "nameCn",
      });
      let message = GetTableDisableMessage({
        total: this.multipleSelected.length,
        ableControlCount: ids.length,
        unableControlCount: unableControl.length,
        nameString: nameString,
        flag: flag,
        ableText: "启用",
        unAbleText: "禁用",
      });
      this.$msgbox({
        title: `${flag ? "禁用" : "启用"}提示`,
        message: message,
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            if (ids.length <= 0) {
              this.$message.info("无有效数据可操作");
              done();
              return;
            }
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            ToogleDisableByIds(ids, flag)
              .then(() => {
                this.$message.success("操作成功");
                this.multipleSelected = [];
                this.onRefresh();
              })
              .catch((err) => {
                ShowApiError("操作请求错误", err);
              })
              .finally(() => {
                done();
                instance.confirmButtonLoading = false;
                instance.confirmButtonText = "";
              });
          } else {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = "";
            done();
          }
        },
      });
    },

    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
    toggleDisableRow(row, index) {
      if (row.canDisable) {
        ToogleDisableByIds([row.id], !row.disabled).then(() => {
          row.disabled = !row.disabled;
          this.$set(this.tableData, index, row);
          this.$message.success("操作成功!");
        });
      } else {
        this.$message.warning("没有权限执行此操作!");
      }
    },

    onFormSuccess() {
      if (isEmprty(this.currentId)) {
        this.page = 0;
      }
      this.onRefresh();
    },

    onUpdateSeriesSuccess() {
      this.onRefresh();
      this.multipleSelected = [];
      this.onUpdateSeriesClose();
    },
    onUpdateSeriesClose() {
      this.showUpdateSeriesDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-image__inner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
