import { apiv1 } from "@/request/apiv1";
// import qs from "qs";

let baseUrl = '/products'

/**
 * 获取启用或者禁用许可
 * @param {String,Number} id 
 */
export function GetDisableCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/disable?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
* 获取修改许可
* @param {String,Number} id 
*/
export function GetUpdateCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/update?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
* 获取修改许可
* @param {String,Number} ids 
*/
export function GetUpdateSeriesCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/update/series?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
* 获取修改许可
* @param {String,Number} ids 
*/
export function GetUpdateSeqSeriesCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/update/seq/series?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
* 获取修改许可
* @param {String,Number} ids 
*/
export function GetUpdateColorCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/update/color?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
* 获取修改许可
* @param {String,Number} ids 
*/
export function GetUpdateNewCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/new?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
* 获取修改许可
* @param {String,Number} ids 
*/
export function GetUpdateShowOnSolutionCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/show/on/solution?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

/**
* 获取修改许可
* @param {String,Number} ids 
*/
export function GetUpdateSeqSolutionCheck(ids = []) {
  return new Promise((resolve, reject) => {
    apiv1.get(`${baseUrl}/can/update/seq/solution?ids=${ids}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}




