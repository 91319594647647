<template>
  <el-dialog
    title="编辑"
    :visible.sync="dialogVisible"
    width="500px"
    :close-on-click-modal="false"
    @close="onDialogClose"
    append-to-body
  >
    <el-select
      v-model="checkValue"
      placeholder="请选择系列"
      style="width:100%"
      :disabled="loading"
    >
      <el-option
        v-for="item in seriesOptions"
        :key="item.id"
        :label="item.name.i18n"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <span slot="footer" class="dialog-footer">
      <el-switch
        v-model="isAdd"
        active-text="添加"
        inactive-text="移除"
        class="float-l"
      >
      </el-switch>
      <el-button @click="dialogVisible = false" :loading="loading"
        >取 消</el-button
      >
      <el-button type="primary" @click="onConfirm" :loading="loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { UpdateProductSeries } from "@/views/products/api";
import { ShowApiError } from "@/request/error";
import options from "../mixins/options";
export default {
  mixins: [options],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pids: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isAdd: true,
      loading: false,
      dialogVisible: false,
      checkValue: null,
      currentProductIds: [],
    };
  },
  watch: {
    pids: {
      handler(value) {
        this.currentProductIds = [...value];
      },
      immediate: true,
      deep: true,
    },
    show: {
      handler(value) {
        this.dialogVisible = value;
        if (this.dialogVisible) {
          this.refreshSeriesOptions();
        }
      },
      immediate: true,
    },
  },
  methods: {
    onDialogClose() {
      this.dialogVisible = false;
      this.$emit("close", this.dialogVisible);
    },
    async onConfirm() {
      try {
        if (this.checkValue == null) {
          this.$message.warning("请选择产品系列");
          return;
        }
        if (this.currentProductIds.length <= 0) {
          this.$message.warning("请先选择要设置产品");
          return;
        }
        let datas = this.currentProductIds.map((value) => {
          return {
            id: value,
            seriesId: this.checkValue,
          };
        });
        this.loading = true;
        await UpdateProductSeries(datas, { add: this.isAdd });
        this.$message.success("操作成功");
        this.checkValue = null;
        this.currentProductIds = [];
        this.$emit("success");
      } catch (error) {
        ShowApiError("操作失败", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
