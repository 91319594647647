import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["colorsOptions", "seriesOptions", "seriesLoading"]),
  },
  methods: {
    ...mapActions({
      refreshColorsOptions: "product/refreshColorsOptions",
      refreshSeriesOptions: "product/refreshSeriesOptions",
    }),
    initFormOptions() {
      this.refreshColorsOptions();
      this.refreshSeriesOptions();
    },
    remoteMethod(query) {
      this.refreshSeriesOptions({
        nameCn: query
      })
    }
  },
}