<template>
  <section class="filter-wrap">
    <BasicFilterWrap
      :moreFilter="showMoreFilter"
      @confrim="onSearch"
      @reset="onResetFilter"
      @more="showMoreFilter = !showMoreFilter"
    >
      <el-form ref="form" :model="tableFilters" label-width="auto">
        <el-row :gutter="20">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="英文名称">
              <el-input
                v-model="tableFilters.nameEn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
                @clear="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="中文名称">
              <el-input
                v-model="tableFilters.nameCn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
                @clear="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="创建日期">
              <el-date-picker
                v-model="tableFilters.createdAt"
                type="daterange"
                style="width: 100%"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                value-format="timestamp"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-collapse-transition>
          <el-row :gutter="10" v-if="showMoreFilter">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="系列">
                <el-select
                  v-model="tableFilters.seriesId"
                  placeholder="请选择系列"
                  class="form-select"
                  clearable
                >
                  <el-option
                    v-for="item in seriesOptions"
                    :key="item.id"
                    :label="item.name.i18n"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="色系">
                <el-select
                  v-model="tableFilters.colorId"
                  placeholder="请选择色系"
                  class="form-select"
                  clearable
                >
                  <el-option
                    v-for="item in colorsOptions"
                    :key="item.id"
                    :label="item.name.i18n"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="推荐产品">
                <el-radio-group v-model="tableFilters.star">
                  <el-radio :label="true">星标</el-radio>
                  <el-radio :label="false">非星标</el-radio>
                  <el-radio :label="null">全部</el-radio>
                </el-radio-group>
               
              </el-form-item>
            </el-col>
            

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建人">
                <BasicSelect
                  v-model="tableFilters.createdById"
                  :allOptions="_userOptions"
                />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新日期">
                <el-date-picker
                  v-model="tableFilters.lastModifiedAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  value-format="timestamp"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新人">
                <BasicSelect
                  v-model="tableFilters.lastModifiedById"
                  :allOptions="_userOptions"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-transition>
      </el-form>
    </BasicFilterWrap>
  </section>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/table";
import BasicFilterWrap from "@/components/BasicFilterWrap";
import { GetProductColorsList } from "@/views/productColors/api";
import { GetSeriesList } from "@/views/productSeries/api";
import options from "../mixins/options";
export default {
  components: {
    BasicSelect,
    BasicFilterWrap,
  },
  mixins: [options],
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: [],
        createdById: null,
        lastModifiedAt: [],
        lastModifiedById: null,
        nameEn: "",
        nameCn: "",
        seriesId: [],
        colorId: [],
      },

      propObj: {},
    };
  },

  computed: {
    _userOptions() {
      return this.$store.getters.userOptions;
    },
    _disableOptions() {
      return this.$store.getters.disableOptions;
    },
  },
  created() {
    this.initFormOptions();
  },

  methods: {
    GetProductColorsList,
    GetSeriesList,
    filterParams() {
      return this.tableFilters;
    },
    onSearch() {
      this.$emit("change", this.filterParams());
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.tableFilters.disabled = false;
      this.initFormOptions();
      this.$emit("change", this.filterParams());
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 10px 10px 0 10px;
  padding: 10px;
  background-color: #fff;
  ::v-deep {
    .el-form-item {
      .el-form-item__content {
        max-height: 40px;
      }

      overflow: hidden;
    }
  }
}
</style>
